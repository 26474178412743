import { MemoData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../../components/View';
import Form from '../../../../../../../containers/Espace/Form';
import params from '../../../../../../../params/memo.json';
import TemplateEspace from '../../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../../utils/requireUser';

const PageMemosUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, channelId, id },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        docId={id}
        itemPathnamePrefix={`/espaces/${espaceId}/canaux/${channelId}/memos/`}
        model={new MemoData({ channelId, espaceId, params })}
        name="memo"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageMemosUpdate);
